import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { AccessPointList } from "@api/throughShipmentApi";
import PageTitle from "@component/PageTitle";
import { dealerState, fetchAccessPointList, fetchDeleteAccessPoint, setAccessPointFilter } from "@redux/dealerSlice";
import { Button, Modal, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import EditAccessPointPopup from "./EditAccessPointPopup";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;

  .ant-table-tbody {
    .ant-table-placeholder > td {
      border: 0;
    }
  }
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function AccessPoint() {
  const dispatch = useDispatch();
  const { dealerInfo, accessPointList, accessPointFilter, isFetching } = useSelector(dealerState);

  const [page, setPage] = useState<number>(1);
  const [editAccessPointPopup, setEditAccessPointPopup] = useState<boolean>(false);
  const [editAccessPoint, setEditAccessPoint] = useState<AccessPointList | undefined>();

  const setPageLimit = (value: SelectValue) => {
    dispatch(setAccessPointFilter({ ...accessPointFilter, limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(setAccessPointFilter({ ...accessPointFilter, offset: (value - 1) * accessPointFilter.limit }));
  };

  const onDeleteAccessPoint = (accessPointId: number) => {
    Modal.confirm({
      title: "你確定要刪除嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchDeleteAccessPoint(accessPointId));
      },
    });
  };

  const columns: ColumnsType<AccessPointList> = [
    {
      key: "id",
      dataIndex: "id",
      title: "編號",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "倉點名稱",
    },
    {
      key: "address",
      dataIndex: "address",
      title: "地址",
    },
    {
      key: "code",
      dataIndex: "code",
      title: "郵遞區號",
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_, values) => (
        <Row justify="end">
          <Button
            type="link"
            onClick={() => {
              setEditAccessPoint(values);
              setEditAccessPointPopup(true);
            }}
          >
            編輯
          </Button>
          <Button type="link" onClick={() => onDeleteAccessPoint(values.id)}>
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchAccessPointList({ ...accessPointFilter, retailerProductline: dealerInfo!.id }));
  }, [dispatch, accessPointFilter, dealerInfo]);

  return (
    <Wrapper>
      <PageTitle title="通路/經銷商 - 通路倉點" />
      <Row justify="space-between">
        <div>
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => setEditAccessPointPopup(true)}
          >
            新增通路倉點
          </CustomButton>
        </div>
        <Row align="middle">
          <PageText>{`總共${accessPointList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={accessPointList.results}
          pagination={{
            pageSize: accessPointFilter.limit,
            current: page,
            showSizeChanger: false,
            total: accessPointList.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {editAccessPointPopup && (
        <EditAccessPointPopup
          accessPoint={editAccessPoint}
          onClose={() => {
            setEditAccessPoint(undefined);
            setEditAccessPointPopup(false);
          }}
        />
      )}
    </Wrapper>
  );
}
