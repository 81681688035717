import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, Col, Form, Row, Select, Input, DatePicker } from "antd";
import { ApprovalStatus, ShippingStatus } from "@api/throughShipmentApi";
import {
  externalOrderState,
  fetchRetailList,
  setExternalOrdersFilter,
  fetchStaffList,
} from "@redux/externalOrderSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_TW";

const timeRange = [
  {
    label: "今天",
    value: "today",
  },
  {
    label: "昨天",
    value: "yesterday",
  },
  {
    label: "上週",
    value: "lastWeek",
  },
  {
    label: "上個月",
    value: "lastMonth",
  },
  {
    label: "最近7天",
    value: "last7days",
  },
  {
    label: "最近30天",
    value: "last30days",
  },
  {
    label: "自訂",
    value: "custom",
  },
];

const CustomRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;
const Wrapper = styled(Form)`
  padding: 24px 0px 0px 16px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function ExternalOrderFilter() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { retailList, externalOrdersFilter, staffListResult } = useSelector(externalOrderState);

  const onChangeTimeMethod = (fieldName: "createdAt" | "shippingAt", value: string) => {
    let timeset: ReturnType<typeof moment>[];

    switch (value) {
      case "today":
        timeset = [moment(), moment()];
        break;
      case "yesterday":
        timeset = [moment().subtract(1, "d"), moment().subtract(1, "d")];
        break;
      case "lastWeek":
        timeset = [moment().weekday(-6), moment().weekday(0)];
        break;
      case "lastMonth":
        timeset = [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")];
        break;
      case "last7days":
        timeset = [moment().subtract(6, "days"), moment()];
        break;
      case "last30days":
        timeset = [moment().subtract(29, "days"), moment()];
        break;
      default:
        timeset = [];
        break;
    }

    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      [fieldName]: timeset,
    });
  };

  const onReset = () => {
    form.resetFields();
    dispatch(
      setExternalOrdersFilter({
        ...externalOrdersFilter,
        createdAtAfter: undefined,
        createdAtBefore: undefined,
        shippingAtAfter: undefined,
        shippingAtBefore: undefined,
        orderNumber: undefined,
        approvalStatus: undefined,
        retailerName: undefined,
        retailerNameQ: undefined,
        shippingAt: undefined,
        shippingStatus: undefined,
        staff: undefined,
      }),
    );
    dispatch(fetchRetailList({}));
  };

  const onSubmit = (values: any) => {
    dispatch(
      setExternalOrdersFilter({
        ...externalOrdersFilter,
        ...values,
        createdAtAfter: values.createdAt ? moment(values.createdAt[0]).format("YYYY-MM-DD") : undefined,
        createdAtBefore: values.createdAt ? moment(values.createdAt[1]).format("YYYY-MM-DD") : undefined,
        shippingAtAfter: values.shippingAt ? moment(values.shippingAt[0]).format("YYYY-MM-DD") : undefined,
        shippingAtBefore: values.shippingAt ? moment(values.shippingAt[1]).format("YYYY-MM-DD") : undefined,
        createdAt: undefined,
        shippingAt: undefined,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchRetailList({}));
    dispatch(fetchStaffList());
  }, [dispatch]);

  const retailerOptions = retailList.results.map((item) => {
    return { value: item.name, label: item.name, text: item.id };
  });

  return (
    <Wrapper colon={false} labelAlign="left" form={form} initialValues={{}} onFinish={onSubmit}>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item label="訂單編號" name="orderNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="合作通路" name="retailerName">
            <Select
              showSearch
              placeholder="請選擇"
              optionFilterProp="children"
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              options={retailerOptions}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="退倉單狀態" name="shippingStatus">
            <Select defaultValue="-100">
              <Select.Option value="-100" disabled>
                請選擇
              </Select.Option>
              <Select.Option value={ShippingStatus.DEPLOY_PENDING}>待拋單</Select.Option>
              <Select.Option value={ShippingStatus.DEPLOY_SUCCESS}>訂單處理中</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item label="訂單申請人" name="staff">
            <Select defaultValue="-100">
              <Select.Option value="-100" disabled>
                請選擇
              </Select.Option>
              {staffListResult.results.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="審核狀態" name="approvalStatus">
            <Select defaultValue="-100">
              <Select.Option value="-100" disabled>
                請選擇
              </Select.Option>
              <Select.Option value={ApprovalStatus.APPROVED}>審核完成</Select.Option>
              <Select.Option value={ApprovalStatus.INVALID}>作廢</Select.Option>
              <Select.Option value={ApprovalStatus.REJECTED}>審核未通過</Select.Option>
              <Select.Option value={ApprovalStatus.AUDIT}>審核中</Select.Option>
              <Select.Option value={ApprovalStatus.PENDING}>待審核</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={5}>
          <Form.Item label="訂單建立時間">
            <Select defaultValue="custom" onChange={(value) => onChangeTimeMethod("createdAt", value)}>
              {timeRange.map((time) => (
                <Select.Option key={time.value} value={time.value}>
                  {time.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="createdAt">
            <CustomRangePicker locale={locale} />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="倉庫建立時間">
            <Select defaultValue="custom" onChange={(value) => onChangeTimeMethod("shippingAt", value)}>
              {timeRange.map((time) => (
                <Select.Option key={time.value} value={time.value}>
                  {time.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="shippingAt">
            <CustomRangePicker locale={locale} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={24}>
          <Row justify="end">
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
