import { PlusCircleTwoTone } from "@ant-design/icons";
import returnOrderApi from "@api/returnOrderApi";
import { transformAPIKeyToCamel } from "@api/utils/transformAPIKeyToCamel";
import ImportFileModal from "@component/ImportFileModal";
import PageTitle from "@component/PageTitle";
import { ReviewState } from "@constant/ReviewState";
import { hideLoading, showLoading } from "@redux/notifySlice";
import {
  applyOrApproveReturnOrder,
  fetchDistributions,
  fetchOrderDetail,
  returnOrderState,
  setErrorResult,
} from "@redux/returnOrderSlice";
import { Button, Col, message, Modal, Row, Select, Skeleton, Space, Tabs } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { EditFormActionType } from "./constant";
import EditOrderForm from "./EditOrderForm";
import {
  ReturnOrderProcessType,
  ReturnProduct,
  ReturnProductErrorResult,
  ReturnProductErrorStatus,
} from "./interfaces";
import OrderFormProvider from "./order-form-provider";
import OrderDetails from "./OrderDetails";
import ReturnProductForm from "./ReturnProductForm";
import ReturnProductList from "./ReturnProductList";

const { TabPane } = Tabs;
const { Option } = Select;

const StyledWrapper = styled.section`
  margin: 16px 25px;
  padding: 8px 11px;
`;
const ModalContent = styled.section`
  height: 150px;
  overflow: auto;
`;

export default function ReturnOrderDetailPage() {
  const dispatch = useDispatch();
  const { returnOrderDetail, isApplicationSended, errorResult } = useSelector(returnOrderState);
  const { returnOrderId } = useParams();
  const numberOrderId = Number(returnOrderId);
  const [editFormState, setEditFormState] = useState(false);
  const [productList, setProductList] = useState<ReturnProduct[]>([]);
  const [activeTab, setActiveTab] = useState("details");
  const [showProductFormModal, setShowProductFormModal] = useState<boolean>(false);
  const [productFormModal, setProductFormModal] = useState<ReturnProduct>();
  const [showImportModal, setShowImportModal] = useState(false);
  const [page, setPage] = useState({
    limit: 20,
    current: 1,
  });
  const [editFromAction, setEditFromAction] = useState<EditFormActionType>(EditFormActionType.UPDATE);

  const disabledSubmit =
    !returnOrderDetail?.isSubmitted || returnOrderDetail?.reviewState !== ReviewState.REVIEW_PENDING;

  const editDisable = () => {
    let result = false;
    if (returnOrderDetail?.shipmentState! >= 11 || returnOrderDetail?.destroyed) result = true;
    if (returnOrderDetail?.reviewState === ReviewState.REVIEW_REJECT) {
      result = false;
    } else if (returnOrderDetail?.isSubmitted) {
      result = true;
    }

    return result;
  };

  function sendApplication() {
    if (productList.length) {
      Modal.warning({
        title: "你確定要送出申請？",
        onOk: () => {
          if (returnOrderDetail) {
            dispatch(
              applyOrApproveReturnOrder({
                orderId: returnOrderDetail?.id,
                processType: ReturnOrderProcessType.APPLY,
              }),
            );
          }
        },
      });
    }
  }

  function switchTab(activeKey: string) {
    setActiveTab(activeKey);
  }

  const approveReturnOrder = async (processType: ReturnOrderProcessType) => {
    if (returnOrderDetail) {
      dispatch(
        applyOrApproveReturnOrder({
          orderId: returnOrderDetail?.id,
          processType,
        }),
      );
    }
  };

  function changePageSize(value: SelectValue) {
    setPage((prevState) => ({
      ...prevState,
      limit: Number(value),
    }));
  }
  function changePage(selectedPage: any) {
    window.scrollTo(0, 0);
    setPage((prevState) => ({
      ...prevState,
      current: selectedPage,
    }));
  }

  async function getReturnOrderProducts(id: number, limit: number, currentPage: number) {
    try {
      const list = await returnOrderApi.fetchReturnOrderProductList({
        orderId: id,
        limit,
        currentPage,
      });
      setProductList(list);
    } catch (error) {
      // handle error
    }
  }

  function createNewProduct(payload: { sku: string; orderedQty: number; effectiveDate: string }) {
    if (!returnOrderDetail) {
      return;
    }
    returnOrderApi
      .addOrderProduct({
        orderId: returnOrderDetail.id,
        sku: payload.sku,
        qty: payload.orderedQty,
        effectiveDate: payload.effectiveDate,
      })
      .then((product) => {
        setProductList((prevState) => {
          return [...prevState, product];
        });
        message.success("新增成功");
      })
      .catch((error) => {
        message.error(`商品新增失敗: ${error.message}`);
      });
  }
  function deleteProduct(orderId: number, ladingLineId: number) {
    returnOrderApi
      .deleteOrderProduct(orderId, ladingLineId)
      .then(() => {
        message.success("刪除成功");
        getReturnOrderProducts(numberOrderId, page.limit, 1);
      })
      .catch((error) => {
        message.error(`刪除失敗: ${error.message}`);
      });
  }

  function modifyProduct(payload: { orderedQty: number; effectiveDate: string; sku: string }) {
    if (returnOrderDetail) {
      returnOrderApi
        .updateReturnProduct({
          orderId: returnOrderDetail.id,
          sku: payload.sku,
          ladingLineId: productFormModal!.id,
          qty: payload.orderedQty,
          effectiveDate: payload.effectiveDate,
        })
        .then((resp) => {
          const oldProductRemoved = productList.filter((product) => product.id !== productFormModal!.id).concat([resp]);
          setProductList(oldProductRemoved);
          dispatch(fetchOrderDetail(numberOrderId));
          getReturnOrderProducts(numberOrderId, page.limit, 1);
          message.success("修改成功");
        })
        .catch((error) => {
          message.error(`修改失敗: ${error.message}`);
        });
    }
  }

  async function importReturnProductXLS(file: File) {
    try {
      setShowImportModal(false);
      dispatch(showLoading());
      await returnOrderApi.importReturnProductXLS(numberOrderId, file);
      message.success("匯入成功");
      getReturnOrderProducts(numberOrderId, page.limit, 1);
    } catch (error: any) {
      const { result } = error;
      if (result?.length > 0) {
        const errorResults = transformAPIKeyToCamel(result);
        const errorItems = new Map();
        errorResults.forEach((item: ReturnProductErrorResult) => {
          const { errReason } = item;
          switch (errReason) {
            case ReturnProductErrorStatus.DUPLICATE:
            case ReturnProductErrorStatus.QTY_EXCEED:
              if (!errorItems.has(errReason)) {
                errorItems.set(errReason, [item]);
              }
              break;
            default: {
              // ReturnProductErrorStatus.OTHER
              const others = errorItems.get(errReason) ? errorItems.get(errReason) : [];
              errorItems.set(errReason, [...others, item]);
            }
          }
        });
        const errorItemParse = Array.from(errorItems.values()).reduce(
          (acc, cur) => acc.concat(cur),
          [],
        ) as ReturnProductErrorResult[];
        Modal.warning({
          title: "部分商品資料有誤，已標記紅色，請修正",
          content: (
            <ModalContent>
              {errorItemParse.map((item, idx) => {
                return (
                  <div>{`${idx + 1}. ${item.msg} ${
                    item.errReason === ReturnProductErrorStatus.OTHER && item.sku ? `(sku:${item.sku})` : ""
                  }`}</div>
                );
              })}
            </ModalContent>
          ),
          okText: "我知道了",
        });
        getReturnOrderProducts(numberOrderId, page.limit, 1);
        return;
      }
      message.error("匯入失敗,請重新上傳");
    } finally {
      dispatch(hideLoading());
    }
  }

  const onCloseEditOrderForm = useCallback(() => {
    setEditFormState(false);
  }, []);

  const onEditFormActionChange = (action: EditFormActionType) => {
    setEditFromAction(action);
    setEditFormState(!editFormState);
  };

  useEffect(() => {
    dispatch(fetchDistributions());
  }, [dispatch]);

  useEffect(() => {
    if (errorResult && errorResult?.length !== 0) {
      Modal.warning({
        title: "部分商品未複製成功，詳細如下",
        content: (
          <ModalContent>
            {errorResult.map((error, idx) => (
              <div key={error.sku}>{`${idx + 1}. ${error.msg} (sku : ${error.sku})`}</div>
            ))}
          </ModalContent>
        ),
        okText: "我知道了",
        onOk: () => {
          dispatch(setErrorResult([]));
        },
      });
    }
  }, [dispatch, errorResult]);

  useEffect(() => {
    // fetch return order products
    getReturnOrderProducts(numberOrderId, page.limit, page.current);
  }, [page, numberOrderId]);

  useEffect(() => {
    // fetch order detail
    dispatch(fetchOrderDetail(numberOrderId));
  }, [numberOrderId, dispatch, productList.length]);

  const canEditStatus = () => {
    let result = false;
    if (returnOrderDetail) {
      if (returnOrderDetail.destroyed) result = true;
      if (returnOrderDetail.reviewState === ReviewState.REVIEW_REJECT) {
        result = false;
      } else if (returnOrderDetail.isSubmitted) {
        result = true;
      }
    }
    return result;
  };
  const canSubmit = useMemo(() => {
    return (
      !productList.length ||
      isApplicationSended ||
      productList.some((product) => product.errReason && product.errReason.length !== 0)
    );
  }, [isApplicationSended, productList]);
  return (
    <StyledWrapper style={{ position: "relative", border: "1px solid #F0F0F0" }}>
      <PageTitle title={`還貨 - ${returnOrderDetail?.orderNumber}`} />
      {returnOrderDetail ? (
        <Tabs onChange={switchTab} type="card">
          <TabPane tab="編輯還貨單" key="details">
            <OrderFormProvider>
              <OrderDetails orderItem={returnOrderDetail || {}} />
            </OrderFormProvider>
          </TabPane>
          <TabPane tab="還貨商品" key="products">
            <Row gutter={[8, 8]}>
              <Col>
                <Button
                  type="primary"
                  icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
                  onClick={() => setShowProductFormModal(true)}
                  disabled={canEditStatus()}
                >
                  新增商品
                </Button>
              </Col>
              <Col>
                <Button onClick={() => setShowImportModal(true)} disabled={canEditStatus()}>
                  匯入商品
                </Button>
              </Col>
              {/* 分頁 */}
              <Col flex="auto">
                <Row justify="end">
                  <Space>
                    <span>{`總共${productList.length && productList[0].totalCount}筆，每頁顯示`}</span>
                    <Select defaultValue="20" style={{ width: "72px", fontSize: "13px" }} onChange={changePageSize}>
                      <Option value="20">20</Option>
                      <Option value="50">50</Option>
                      <Option value="100">100</Option>
                    </Select>
                    <span>筆</span>
                  </Space>
                </Row>
              </Col>
            </Row>
            {/* 還貨商品列表 */}
            <ReturnProductList
              list={productList}
              openProductForm={(product) => {
                setProductFormModal(product);
                setShowProductFormModal(true);
              }}
              deleteProduct={deleteProduct}
              pagination={{
                ...page,
              }}
              isApplicationSended={canEditStatus()}
              onChange={changePage}
            />
          </TabPane>
        </Tabs>
      ) : (
        <Skeleton />
      )}
      <div style={{ position: "absolute", top: 9, right: 20 }}>
        {activeTab === "details" ? (
          <>
            <Space>
              <Button
                type="default"
                disabled={disabledSubmit}
                onClick={() => approveReturnOrder(ReturnOrderProcessType.REJECT)}
              >
                審核不通過
              </Button>
              <Button
                type="default"
                disabled={disabledSubmit}
                onClick={() => approveReturnOrder(ReturnOrderProcessType.APPROVE)}
              >
                審核通過
              </Button>
              <Button type="default" onClick={sendApplication} disabled={canSubmit}>
                送出申請
              </Button>
              <Button type="default" onClick={() => onEditFormActionChange(EditFormActionType.COPY)}>
                複製
              </Button>
              <Button
                type="primary"
                onClick={() => onEditFormActionChange(EditFormActionType.UPDATE)}
                disabled={editDisable()}
              >
                編輯
              </Button>
            </Space>
          </>
        ) : (
          <Button type="default" onClick={sendApplication} disabled={canSubmit}>
            送出申請
          </Button>
        )}
      </div>
      <OrderFormProvider>
        <EditOrderForm
          isModalVisible={editFormState}
          action={editFromAction}
          closeModal={onCloseEditOrderForm}
          returnOrderDetail={returnOrderDetail}
        />
      </OrderFormProvider>
      {showProductFormModal && (
        <ReturnProductForm
          closeModal={() => {
            setProductFormModal(undefined);
            setShowProductFormModal(false);
          }}
          createNewProduct={createNewProduct}
          modifyProduct={modifyProduct}
          product={productFormModal}
          productList={productList}
          returnOrderDetail={returnOrderDetail}
        />
      )}
      {showImportModal && (
        <ImportFileModal
          csvErrorMessage=""
          closePopup={() => setShowImportModal(false)}
          clearErrorMessage={() => {}}
          onAddByFile={importReturnProductXLS}
          templatePath="admin/template/xls/return_inventory_import_template.xlsx"
          fileType=".xlsx"
        />
      )}
    </StyledWrapper>
  );
}
